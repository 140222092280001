import React from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const product_marketing_manager = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Product Marketing Manager. Apply to join our team!',
    },
    keywords: {
      keywords: 'Product Marketing Manager,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/3AEjKYqGn0mhjWaLV90F7R/c5d71d8bb19b5d4583f928200bdad86c/product-marketing-manager.jpg',
      },
    },
    title: {
      title: 'Product Marketing Manager',
    },
  }

  const link = 'https://apply.workable.com/lensesio/j/A71B1BF0F5/'
  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Product Marketing Manager</h1>
          <p className="meta">Remote (North America)</p>
          <section className="section section--text">
            <WhoWeAre />
            <h5 className="pt-3">What your day-to-day looks like:</h5>
            <ul className="pl-4 f-16">
              <li>Manage product go-to-market strategy and execution</li>
              <li>
                Contribute to our product roadmap and develop customer personas
              </li>
              <li>
                Work closely with engineering, sales and leadership to
                communicate differentiators and build value propositions for
                specific markets
              </li>
              <li>
                Develop integrated campaigns with our Demand Generation team
              </li>
              <li>Engage with customers and prospects in the field</li>
              <li>
                Partner with Communications teams to brief analysts and the
                press
              </li>
              <li>
                Support our Content team by developing assets including videos
              </li>
            </ul>
          </section>
          <section className="section section--text">
            <h5 className="pt-3">What you will bring</h5>
            <ul className="pl-4 f-16">
              <li>
                Hands-on experience in marketing to software/data engineering
              </li>
              <li>Proven track record of taking products to market</li>
              <li>Background in engineering</li>
              <li>Experience working closely with sales team</li>
              <li>
                Success executing campaigns across multiple channels and tactics
              </li>
              <li>Great literacy skills and articulate in presentation</li>
              <li>Creative marketing ideas and concepts</li>
            </ul>

            <h5 className="pt-3">Passionate about</h5>
            <ul className="pl-4 f-16">
              <li>Technology</li>
              <li>Customer Success</li>
              <li>Trying new tactics and messaging</li>
              <li>Being part of a team</li>
              <li>Sharing success with others</li>
              <li>Inspiring people</li>
            </ul>
          </section>

          <section className="section section--text">
            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default product_marketing_manager
